import React from "react";
import {Navigate, NavLink, useNavigate} from "react-router-dom";

import { Login } from "../../components/Login/Login";
import { ACDelcoLogo, LanguageSelect } from "../../components/UI";
import {
  TranslationMixin,
  Divider,
  Navbar,
  NavItem,
  NavItemGroup,
  Menu,
  MenuItem,
} from "tds_shared_ui";
import {
  WithCommonTranslations,
  WithRegion,
  WithUser,
} from "../../components/hocs";
import { AuthService } from "tds_shared_ui";
import { ShoppingCartIcon } from "../../components/Cart/ShoppingCartIcon";
import { NotificationIcon } from "../../components/Notifications/NotificationIcon";
import { SpsCallibration, RpoLookupService, } from "../../services";
import { AlertMixin, withRouter } from "tds_shared_ui";
import "./AppHeader.css";
import { ResourceLoginCheck } from "../../components/UI/ResourceLoginCheck";

const navItems = [
  { label: "LABEL_SUBSCRIPTIONS", link: "/subscriptions" },
  {
    label: "LABEL_KEYCODES",
    link: "/keycodes",
    display: function (regionData) {
      return regionData.regionConfigs.keycodeAvailable === "true";
    },
  },
  {
    label: "LABEL_MY_ORDERS",
    link: "/myorders",
    display: function (regionData, user) {
      return user && (user !== "null" || user !== "undefined");
    },
  },
  {
    label: "LABEL_STORE",
    link: "/store",
    display: function (regionData) {
      if (regionData.regionConfigs.storeLink) {
        return true;
      }
    },
  },
];

export const AppHeader = withRouter(
  WithRegion(
    WithUser(
      WithCommonTranslations(
        class extends AlertMixin(TranslationMixin(React.Component)) {
          constructor(props) {
            super(props);
            this.state = {
              isUserNotLoggedIn: false,
              errorMessage: '',
            };

            this.iconsMenu = [
              {
                label: "LABEL_ALERTS",
                icon: "bell",
                render: function (props) {
                  return (
                    <NotificationIcon
                      showNotification={props.displayNotification}
                      onHide={() => props.setNotificationDisplay(false)}
                    />
                  );
                },
              },
              {
                label: "LABEL_CART",
                icon: "shopping-cart",
                render: function (props) {
                  return (
                    <ShoppingCartIcon
                      showCart={props.displayCart}
                      locale={props.locale}
                      onHide={() => props.setCartDisplay(false)}
                    />
                  );
                },
              },
              { label: "LABEL_FAQ", link: "/help", icon: "question" },
            ];
          }

            handleNotLoggedInMessage = () => {
            this.setState({
              isUserNotLoggedIn: false,
              errorMessage: '',
            });
          };

          handleClick(link, newWindow) {
            // Define the action that should be taken when the SPS link is clicked
            const spsLoginAction = () => {
              SpsCallibration.validateSPSLogin().then(
                (html) => {
                  let win = window.open("", "");
                  this.setState({ isUserNotLoggedIn: false, errorMessage: '' }, function () {
                    console.log(this.state.isUserNotLoggedIn);
                  });
                  win.document.write(html);
                  win.document.redirectForm.submit();
                },
                (reason) => {
                  this.setState({ isUserNotLoggedIn: true, errorMessage: this.getTranslation("ERROR_LOGIN_FOR_CALID") }, function () {
                    console.log(this.state.isUserNotLoggedIn);
                  });
                }
              ).catch((e) => {
                //Adding empty catch to prevent webpack unhandled exception msg
              });
            };

            //Define the action that should be taken when the RPO Lookup is clicked
            const rpoLookupLoginAction = () => {
              RpoLookupService.validateLogin().then((data) => {
                  this.setState({
                      isUserNotLoggedIn: false,
                      errorMessage: '',
                  }, () => {
                      this.props.navigate("/rpolookup");
                  });
              }).catch((e) => {
                this.setState({ isUserNotLoggedIn: true, errorMessage: this.getTranslation("ERROR_LOGIN_FOR_RPOLOOKUP") }, function () {
                  console.log(this.state.isUserNotLoggedIn);
                });
              });
            };

            // Define the actions that can be taken when a link is clicked
            const actions = {
              'https://sporef.pp.xw.gm.com/sporef/verifyaccess?appParm=sps2info': spsLoginAction,

              'https://sporef.xw.gm.com/sporef/verifyaccess?appParm=sps2info': spsLoginAction,

              '/rpolookup': rpoLookupLoginAction,

              'default': () => {
                if (newWindow) {
                  window.open(link, "_blank");
                } else {
                  if (link !== undefined) {
                    this.props.navigate(link);
                  }
                }
              }
            };

            // Execute the action if it exists in the actions object, otherwise execute the default action
            (actions[link] || actions['default'])();
          }

          handleLogout = () => {
            AuthService.logout()
              .then(() => {
                this.props.setUser(null);
                if (this.props.location.pathname === "/profile") {
                  this.props.navigate("/");
                }
              })
              .catch((error) => {
                alert(error);
              });
          };

          renderLoginForNotLoggedInUser() {
            return <Login className="login" locale={this.props.locale} />;
          }

          renderLogoutForLoggedInUser() {
            if (this.props.user) {
              const name =
                this.props.user.firstName + " " + this.props.user.lastName;

              return (
                <div className="logout-div">
                  <NavItem>
                    <Menu title={name} label={name} menuAlignment="left">
                      <MenuItem
                        label={this.getTranslation("LABEL_PROFILE")}
                        title={this.getTranslation("LABEL_PROFILE")}
                        onClick={() => this.handleClick("/profile")}
                      />
                      <MenuItem
                        label={this.getTranslation("LABEL_CHANGE_PASSWORD")}
                        title={this.getTranslation("LABEL_CHANGE_PASSWORD")}
                        onClick={() => this.handleClick("/changepassword")}
                      />
                      <MenuItem
                        label={this.getTranslation("LABEL_HELP")}
                        title={this.getTranslation("LABEL_HELP")}
                        onClick={(e) => this.handleClick("/help")}
                      />
                      <Divider />
                      <MenuItem onClick={this.handleLogout}>
                        <i className="fas fa-sign-out-alt pr-1"></i>{" "}
                        {this.getTranslation("LABEL_LOGOUT")}
                      </MenuItem>
                    </Menu>
                  </NavItem>
                </div>
              );
            }
          }

          renderNavItem(navItem) {
            let dom = (
              <NavItem key={navItem.label}>
                <NavLink to={navItem.link} className={(navData) => navData.isActive ? "active" : "none"} style={{textDecoration: 'none'}}>
                  {this.getTranslation(navItem.label)}
                </NavLink>
              </NavItem>
            );

            if (navItem.hasOwnProperty("display")) {
              return navItem.display(this.props.regionData, this.props.user)
                ? dom
                : "";
            } else {
              return dom;
            }
          }

          render() {
            const languageData = this.props.regionData;
            return (
              <div className="appHeader">
                <Navbar
                  type="navbar"
                  className="tdsNavbar"
                  appTitle={
                    <ACDelcoLogo
                      imageAlt={this.getTranslation("LABEL_LOGO")}
                      onClick={() => this.handleClick("/")}
                    />
                  }
                >
                  {navItems.map((navItem) => this.renderNavItem(navItem))}

                  <NavItem>
                    {this.props.resourceLinks && (
                      <Menu
                        label={this.getTranslation("LABEL_RESOURCES")}
                        className="tdsMenu"
                      >
                        {this.props.resourceLinks.map((menuItem) => (
                          <MenuItem
                            key={menuItem.id}
                            title={menuItem.description}
                            onClick={() => this.handleClick(menuItem.url, true)}
                          >
                            {menuItem.name}
                          </MenuItem>
                        ))}
                      </Menu>
                    )}
                    {this.state.isUserNotLoggedIn ? (
                      <ResourceLoginCheck
                        locale={this.props.locale}
                        isUserNotLoggedIn={this.state.isUserNotLoggedIn}
                        handleLoginCheck={this.handleNotLoggedInMessage}
                        errorMessage={this.state.errorMessage}
                      />
                    ) : (
                      <i></i>
                    )}
                  </NavItem>

                  <NavItemGroup alignment="end">
                    <NavItem>
                      {this.props.user
                        ? this.renderLogoutForLoggedInUser()
                        : this.renderLoginForNotLoggedInUser()}
                    </NavItem>
                    {this.iconsMenu.map((iconMenu) => (
                      <NavItem
                        key={iconMenu.label}
                        title={this.getTranslation(iconMenu.label)}
                        onClick={() => this.handleClick(iconMenu.link)}
                      >
                        {iconMenu.render ? (
                          iconMenu.render(this.props)
                        ) : (
                          <i className={"fas fa-" + iconMenu.icon}></i>
                        )}
                      </NavItem>
                    ))}

                    <NavItem className="tdsLanguage">
                      <LanguageSelect
                        list={languageData.supportedLanguages}
                        locale={this.props.locale}
                        onSelect={this.props.onLocaleChange}
                      />
                    </NavItem>
                  </NavItemGroup>
                </Navbar>
              </div>
            );
          }
        }
      )
    )
  )
  );
