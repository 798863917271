import React from "react";
import PropTypes from "prop-types";
import { WithCommonTranslations, WithRegion } from "../hocs";
import { TranslationMixin } from "tds_shared_ui";

import "./PaymentInputFields.css";
import { ThreeDSService } from "../../services";

const { REACT_APP_CHALLENGE_REDIRECT, REACT_APP_API_HOST } = process.env;

export const PaymentInputFields = WithRegion(
  WithCommonTranslations(
    class extends TranslationMixin(React.Component) {
      constructor(props) {
        super(props);
        let updatedTotalAmount = this.props.grandTotalAmount;

        if(updatedTotalAmount.includes("\u20AC")) { //Euro Symbol
          updatedTotalAmount = updatedTotalAmount.replace("\u20AC", "").trim();
        }

        this.state = {
          onEnable3DS: true,
          proceed: false,
          myData: null,
          myError: null,
          myStatus: null,
          myTotal: updatedTotalAmount.includes("CHF")
            ? updatedTotalAmount.replace("CHF", "").replace(/\,/g, "").trim()
            : updatedTotalAmount.includes(",")
              ? updatedTotalAmount
                .replace(/\./g, "")
                .split(" ")[0]
                .replace(",", ".")
              : updatedTotalAmount,
        };
      }

      componentDidMount() {
        const { regionData } = this.props;
        const merchantId = regionData.regionConfigs.merchant_id;
        let debug = true;
        if (process.env.REACT_APP_PAYMENT_DEBUG === "false") {
          debug = false;
        }
        let widget = null;
        let me = this;
        me.setState({
          onEnable3DS: merchantId === "TDS-EU" || merchantId === "TDS-CH",
        });
        if (window.GMPaymentUI) {
          try {
            widget = new window.GMPaymentUI({
              containerId: this.props.id,

              mastercardOptions: {
                epgRestService:
                  REACT_APP_API_HOST + "/api/v1/3DS/createSession",
                //use MasterCard test servers
                useTestServers: debug,

                //bac or MasterCard merchantId are required!!!!
                merchantId: merchantId,

                //Display images for accepted cards
                showCardImages: true,

                //error message will be clear when input changes
                clearErrorOnChange: true,

                //error message will be clear when input gains focus
                clearErrorOnFocus: false,

                //error message will be clear when input loses focus
                clearErrorOnBlur: false,

                //text to display on form fields
                translations: {
                  nameOnCard: {
                    label: this.getTranslation("LABEL_NAME_ON_CARD"),
                    placeholder: "",
                    requiredError: this.getTranslation("ERROR_REQUIRED"),
                    invalidError: this.getTranslation("ERROR_INVALID"),
                  },
                  creditCard: {
                    label: this.getTranslation("LABEL_CREDIT_CARD"),
                    placeholder: "",
                    requiredError: this.getTranslation("ERROR_REQUIRED"),
                    invalidError: this.getTranslation(
                      "ERROR_INVALID_CARD_NUMBER"
                    ),
                  },
                  expiryMonth: {
                    label: this.getTranslation("LABEL_MONTH"),
                    placeholder: "",
                    requiredError: this.getTranslation("ERROR_REQUIRED"),
                    invalidError: this.getTranslation("ERROR_INVALID"),
                  },
                  expiryYear: {
                    label: this.getTranslation("LABEL_YEAR"),
                    placeholder: "",
                    requiredError: this.getTranslation("ERROR_REQUIRED"),
                    invalidError: this.getTranslation("ERROR_INVALID"),
                  },
                  securityCode: {
                    label: this.getTranslation("LABEL_CVV"),
                    placeholder: "",
                    requiredError: this.getTranslation("ERROR_REQUIRED"),
                    invalidError: this.getTranslation("ERROR_INVALID"),
                  },
                },

                threeDS: {
                  // OPTIONAL
                  enable3DS: merchantId === "TDS-EU" || merchantId === "TDS-CH",
                  // Enable for 3DS (true), requires a 3DS enabled merchantId
                  challengeRedirectUrl: REACT_APP_CHALLENGE_REDIRECT,
                  // REQUIRED FOR 3DS. Encoded as defined in RFC3986. POST supported redirect
                  fullScreenRedirect: false, // default is to display challenge within a modal
                  userLanguage: "en-AU", // OPTIONAL
                  orderAmount: this.state.myTotal, // REQUIRED FOR 3D
                  currency: this.props.currencyCode, //REQUIRED FOR 3DS
                },
              },

              //callback after a SUCCESSFUL submit to MasterCard payment gateway
              //(e.g. no validation errors and credit card is valid)
              //returns data object with information on submitted fields
              onSuccessCallback: function (data) {
                if (data.paymentType === "credit") {
                  data.response.nameOnCard = data.response.payment.nameOnCard;
                  me.setState({
                    myData: data,
                  });
                }
                if (data.paymentType === "3DS") {
                  data.response.nameOnCard =
                    me.state.myData.response.nameOnCard;
                  data.transactionId = data.response.transactionId;
                  me.setState({
                    myData: data,
                  });
                  return ThreeDSService.get3dsStatus(
                    data.response.transactionId
                  )
                    .then((data) => {
                      me.props.onPaymentSuccess(me.state.myData);
                    })
                    .catch((e) => {
                      if (e.status === 500) {
                        me.props.onPaymentError("Timeout");
                      } else {
                        me.props.onPaymentError(e);
                      }
                    });
                }

                if (me.state.onEnable3DS === false) {
                  me.props.onPaymentSuccess(data);
                }
              },

              //callback when an error occurs
              onErrorCallback: function (error) {
                me.setState({
                  myError: error,
                });
                me.props.onPaymentError(error);
              },
              onStatusCallback: function (status) {
                me.setState({
                  myStatus: status,
                });
              },
            });
          } catch (e) {
            console.log(e);
          }
        }
        this.props.onInit(widget);
      }

      render() {
        return (
          <div className="payment-container">
            <div className="payment-ui" id={this.props.id}></div>
          </div>
        );
      }
    }
  )
);

PaymentInputFields.propTypes = {
  id: PropTypes.string.isRequired,
  onPaymentError: PropTypes.func.isRequired,
  onPaymentSuccess: PropTypes.func.isRequired,
  onInit: PropTypes.func.isRequired,
};
